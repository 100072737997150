/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1244px) {

.container {width: 905px;; margin: 0 auto;}
.wrapper {width: 905px;}
h1 {font-size: 35px;}
h5 {font-size: 20px;}
p {font-size: 16px; line-height: 24px; margin-bottom: 20px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
    padding-top: 15px;
    .logo {width: 115px;}
    .main_menu {
        nav {
            >ul {
                li {
                    a {
                        padding-top: 15px; padding-bottom: 34px; font-size: 16px;
                        &.active {
                           &:before {width: 60%;} 
                        }
                    }
                    &:hover {
                        a {
                            &:before {width: 60%;}
                        }
                    }
                    &.prod {
                        a {
                            &:after {border-left: 8px solid transparent;border-right: 8px solid transparent;border-bottom: 15px solid #000;}
                        }
                    }
                }
            }
        }
    }
}

.sub_prod {
    top: 65px; display: none; width: 905px;
    .wrapper {width: 905px;}
    .content_tab {
        .tab_list {
            width: 800px;
            h6 {margin-bottom: 50px;}
            .tabBrand {
                .slider01, .slider02 {
                    .brand {
                        .listBrand {
                            height: 260px;
                            li {width: 100px; margin-bottom: 25px;}
                        }
                    }
                    .slick-arrow {
                        width: 30px; height: 27px; background-size: 30px !important;
                        &.slick-prev {left: -40px;}
                        &.slick-next {right: -40px;}
                    }
                }
            }
        }
    }
}
.sub_bike {
    padding: 20px 50px 20px 0; margin: 0 -40px; top: 95px;
    .bike_list {height: 304px;}
    .bikes_sub {
        .listBike {
            width: 650px;
            .bike {
                height: 270px;
                ul {
                    li {width: 150px;}
                }
            }
            .slick-arrow {
                right: 75px; z-index: 6; width: 30px; height: 27px; background-size: 30px !important;
                &.slick-prev {top: 37%;}
                &.slick-next {bottom: 37%;}
            }
        }
        .nme_bike {
            margin: 6% 0;
            h6 {margin-bottom: 30px;}
            .back {width: 30px; height: 27px; background-size: 30px !important;}
        }
    }
}
/* middle homepage
----------------------------------------------------------------------------------------------*/
.homepage {
    .homeSlide {
        .caption {
            position: absolute; top: 30%;
            h1 {
                font-size: 50px;
            }
        }
        .images {
            img {max-width: 1600px;}
        }
        /*.slick-dots {
            right: 40px; top: 30%;
            li {
                button {
                    line-height: 42px; height: 42px; font-size: 13px;
                    &:before {
                        font-size: 13px;
                    }
                    &:after {
                        font-size: 14px;
                    }
                }
                &.slick-active,
                &:hover {
                    button {
                        font-size: 30px;
                        &:before {
                            font-size: 30px;
                        }
                    }
                }
            }
        }*/
    }
}

.pg_title {font-size: 30px;}
.text {
    p {font-size: 16px; line-height: 26px;}
}

.sec_milestones {
    padding-bottom: 150px;
    .nav-direction {
        a {
            font-size: 18px; height: 65px;
            &:before {
                width: 35px; height: 38px; background-size: 68px; display: block; margin: 0 auto 10px;
            }
            &.prev {left: 0px;}
            &.next {right: 0px;}
        }
    }
    .miles_content {
        padding: 0 70px;
        .wrapper {width: auto;}
        .slide_miles {
            .miles {
                .images {
                    p.ket_year {
                        span {width: 80%;}
                    }
                }
            }
        }
    }
}
.sec_meetOur {
    background-size: 180%;
    .box_img {
        width: 68%;
        .slider {
            padding-top: 100px;
            .txt_meet {
                height: 400px; border: 2px solid #ccc;
                padding: 0 40px;
            }
        }  
    }
    .img {width: 46%;}
    .right_meet {
        padding-top: 100px; width: 32%;
        p {padding-left: 10px;}
    }
}
.sec_testimoni {
    .pg_title {margin-bottom: 50px;}
    .text_rotate {display: none;}
    .block_testi {
        .sliderTesti {
            border-left: 2px solid #ebebeb;
            &:before, .line2 {left: 0;}
        }
        .textTestimoni {
            width: 48%; padding-left: 25px;
            .slide {
                .txt_testimoni {margin-top: 35%;}
            }
        }
        .Imgtestimoni {
            .img {
                figure {height: 560px;}
                img {width: 100%;}
            }
            .slick-arrow {
                left: -95%; top: 50px;
                &.slick-next {left: -85%;}
            }
        }
    }
}




.prod_pg, .news_pg, .poject_pg {
    .wrapper {width: 905px;}
}
.project.list {
    background-size: 150%; padding-top: 150px;
}
.breadcrumb {
    margin-bottom: 35px;
    a {font-size: 13px;}
}
.top_fil {
    .sort {
        margin-right: 20px;
        label {font-size: 16px;}
        select {font-size: 16px; width: 135px;}
        &.dt {
            select  {width: 80px;}
        }
        &.med {
            select {width: 135px;}
        }
        .cover_combo {
            .inner_combo {font-size: 16px;}
        }
    }
    .right {
        form {
            input[type="text"] {width: 145px; font-size: 13px; padding: 11px 10px;}
            input[type="submit"] {width: 28px;}
        }
    }
}
.asside {
    width: 230px;
    .filter {
        h6 {font-size: 16px;}
        padding: 0 18px 25px;
        .box_price {
            .col {
                width: 94px;
                input {font-size: 12px; padding: 10px 8px;}
            }
        }
        .drop_filt {
            a {font-size: 13px;}
        }
    }
}
.content_right {width: 665px;}
.col_list {
    .col {
        width: 33.33%;
        &:nth-child(4n) {border-right: 1px solid $c_e1;}
        &:nth-child(3n) {border-right: none;}
        .ket_prod {
            h5 {margin-bottom: 0;}
        }
    }
    &.listProject {
        .col {
            padding: 20px 0;
            h6 {margin-top: 15px; font-size: 14px;}
            .img {
               width: 88%; margin: 0 auto;
            }
            &:nth-child(4n) {border-right: none;}
        }
    }
    &.col-5 {
        .col {
            width: 25%;
            &:nth-child(3n),
            &:nth-child(5n) {border-right: 1px solid $c_e1;}
            &:nth-child(4n) {border-right: none;}
        }
    }
}
.pagging {
    a {
        width: 26px; line-height: 26px; font-size: 16px;
        &:hover, &.active {background-size: 100%;}
    }
}
.related {
    .col_list {
        .col {
            &:nth-child(3) {border-right: 1px solid $c_e1;}
        }
    }
}
.qty_input {
    input {font-size: 20px;}
}
.detail_prod {
    padding-top: 40px;
    .img_big {
        padding-left: 30px; width: 38.68%;
        .list_img {width: 350px; height: 350px;}
        .thumb a {width: 75px;}
    }
    .dec_prod {
        width: 51.39%;
        h5 {font-size: 20px;}
        ul {
            width: 94%;
            li {font-size: 18px; margin-bottom: 15px; line-height: 24px;}
        }
        .pqt {
            .ket{
                label {font-size: 16px;}
                &:nth-child(2) {width: 28%;}
            }
        }
    }
}
.detail_listPro {
    .slideSmall {
        margin: 30px 68px 0;
        .slick-arrow {
            &.slick-prev {left: -70px;}
            &.slick-next {right: -70px;}
        }
    }
}
.decription {
    p {font-size: 16px; line-height: 24px;}
}
.btm_cart {
    .right {
        h6 {
            span {font-size: 24px; margin-left: 10px;}
        }
    }
}
.cart_pg {
    padding: 30px 20px;
    .shopCart_list {
        .item {
            .blok {
                padding: 10px;
                h5 {font-size: 18px; line-height: 30px;}
                label {font-size: 14px;}
                &:nth-of-type(1) {width: 200px;}
                &:nth-of-type(2) {
                    width: 230px; padding: 0 25px;
                    h5 {line-height: 22px;}
                }
                &:nth-of-type(3), &:nth-of-type(5) {width: 150px;}
                .qty_input {
                    width: 100px; margin-right: 10px;
                    input {width: 36px;}
                }
            }
        }
    }
    .ckout {
        .row {
            p {font-size: 16px; line-height: 24px;}
            label {font-size: 16px;}
            input[type="text"],input[type="email"],select,textarea {font-size: 16px;}
        }
    }
}



.dot {font-size: 26px;}
.text_thanks {
    width: auto;
    p {font-size: 20px; line-height: 30px; }
    &.karir {
        h1 {font-size: 50px;}
        p {font-size: 20px;}
    }
}
.more_project {line-height: 24px; background-size: 24px; padding-left: 35px;}
.left_project {
    margin-left: 0;
    .project_content {
        width: 100%; float: none;
        .more_project {display: none;}
        .slide_project1 {
            .item {
                .dec_project {
                    .rd_more {
                        font-size: 16px; 
                    }
                }
            }
        }
    }
    .thumb_project {
        float: none; width: 100%;
        .list_thumb {
            width: 525px; margin: 40px auto; padding-bottom: 20px;
            .slick-list {padding: 0 !important;}
            .thumb {
                height: 95px; margin: 20px 5px; background-size: 100% !important;
                &.slick-center {
                    @include transform(scale(1.4));
                }
            }
            .slick-arrow {
                @include transform(rotate(-90deg));
                &.slick-prev { top: 0; bottom: 0; margin: auto; right: auto; left: -60px;}
                &.slick-next {top: 0; bottom: 0; margin: auto; left: auto; right: -60px}
            }
        }
    }
    .more_project {width: 185px;  display: block; margin: 0 auto; font-size: 16px;}
}
.slide_recomend {
    .slide {width: 700px;}
}
.news_pg {
    .date {font-size: 16px; margin-bottom: 30px;}
    .rd_more {font-size: 16px;}
    .big_news {
        .hdline {
            .img {padding: 6% 0;}
            .dec_news {
                padding: 70px 30px;
                h1 {line-height: 40px;}
            }
        }
    }
    .grid_news {
        width: 935px;
        .grid_item {
            width: 33.33%;
            &:nth-of-type(4) {margin-top: 0;}
            &:nth-of-type(4n),&:nth-of-type(7n),&:nth-of-type(11n) {
                .img {top: 0; bottom: auto}
                .dec {bottom: 0; top: auto;}
            }
            &:nth-of-type(6n),&:nth-of-type(9n) {
                .img {bottom: 0; top: auto;}
                .dec {top: 0; bottom: auto;}
            }
        }
    }
}
.detailNews .date {margin-bottom: 10px;}
.slide_recomend {
    .slide {width: 650px;}
    .slick-arrow {
        &.slick-prev { left: 6%;}
        &.slick-next {right: 7%;}
    }
}
.SlideText {
    width: 650px;
}

.listCareer {
    width: 100%; margin-top: 60px;
    .list {
        div {
            &.submit {
                .btn {width: 220px; display: inline-block; line-height: 45px;}
            }
            &.job {width: 38%;}
        } 
    }
}
.detailCareer {margin-top: 80px;}
.img_side_carrer {display: none;}
.contact_sec {
    h2 {font-size: 25px;}
    .list_location {
        .location {
            h2 {font-size: 20px;}
        }
    }
    .contact_form {
        .formContact {
            width: 41%;
            p {font-size: 16px; line-height: 24px;}
        }
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
footer {
    .foot {
        margin: 13px 0;
        &.left {
            ul {
                li {
                    margin: 0 3px;
                    a {width: 32px; height: 32px; background-size: 22px !important;}
                }
            }
        }
        &.center {
            top: -20px;
            ul {
                li {
                    a {padding-right: 16px; margin-right: 16px;}
                }
            }
        }
    }
}



}